import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if the same question is clicked again
    } else {
      setActiveIndex(index); // Open the clicked question
    }
  };

  const faqData = [
    {
      question: 'What types of keywords can I search for?',
      answer: 'You can search for any keyword that appears in Reddit posts, including product names, topics, trending events, or general interests. Whether you\'re tracking your brand reputation or exploring public opinion on a social issue, you can gather valuable insights.',
    },
    {
      question: 'How accurate is the sentiment analysis?',
      answer: 'The sentiment analysis is powered by state-of-the-art transformer models trained on vast datasets. While the model is highly accurate, sentiment detection can sometimes be influenced by context, sarcasm, or ambiguous language. We continuously improve the algorithm to enhance accuracy.',
    },
    {
      question: 'Can I analyze posts from specific subreddits?',
      answer: 'Yes! You can narrow your analysis to particular subreddits to focus on niche communities or specific interest groups. This allows you to gather more targeted insights relevant to a brand, product, or trend.',
    },
    {
      question: 'Is there a limit to how many posts I can analyze?',
      answer: 'Currently, you can analyze up to 1000 posts per search. This includes a mix of the most recent posts and top popular posts from the past decade. For larger datasets or continuous monitoring, we\'re exploring ways to expand this capacity in the future.',
    },
    {
      question: 'Is the tool free to use?',
      answer: 'The tool is free to use for basic analysis up to 1000 posts per search. This is perfect for students or small-scale research projects. For larger analyses or additional features, we plan to offer flexible pricing options in the future.',
    }
  ];



  return (
    <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleAnswer(index)}
            >
              <span>{item.question}</span>
              <span className={`faq-toggle ${activeIndex === index ? 'open' : ''}`}>+</span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
