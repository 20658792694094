import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="terms-container" style={containerStyle}>
      {/* Scoped CSS */}
      <style jsx>{`
        .terms-container h1 {
          font-size: 2.5rem;
          color: #1a3c5e;
          text-align: center;
          margin-bottom: 2rem;
        }
        .terms-container h2 {
          font-size: 1.5rem;
          color: #2b5d87;
          margin-top: 2rem;
          margin-bottom: 1rem;
          border-bottom: 2px solid #e0e7ef;
          padding-bottom: 0.5rem;
        }
        .terms-container p, .terms-container li {
          font-size: 1rem;
          line-height: 1.6;
          color: #333;
          margin-bottom: 1rem;
        }
        .terms-container ul {
          list-style-type: disc;
          margin-left: 2rem;
        }
        .terms-container a {
          color: #007bff;
          text-decoration: none;
        }
        .terms-container a:hover {
          text-decoration: underline;
        }
        @media (max-width: 768px) {
          .terms-container {
            padding: 1rem;
          }
          .terms-container h1 {
            font-size: 2rem;
          }
          .terms-container h2 {
            font-size: 1.25rem;
          }
        }
      `}</style>

      <h1>Terms of Use</h1>
      <p style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
        Effective Date: November 29, 2024
      </p>

      <p>
        Welcome to RedditSentiments! By accessing or using our website (the "Site") or any services provided through it (collectively, the "Service"), you agree to comply with and be bound by the following Terms of Use (the "Terms"). Please read them carefully. If you do not agree with these Terms, you may not use the Service.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        RedditSentiments provides a sentiment analysis tool based on publicly available Reddit posts. By accessing or using the Service, you confirm that you are of legal age to enter into this agreement or have obtained parental or guardian consent if required, and you agree to abide by these Terms. We reserve the right to refuse access to the Service or terminate your use at our sole discretion if you violate these Terms.
      </p>

      <h2>2. Privacy</h2>
      <p>
        Your privacy matters to us. RedditSentiments collects and processes limited user data to operate the Service. We do not sell or share your personal information with third parties, except as required by law or to protect our rights. For a full explanation of how we handle your data, please review our Privacy Policy.
      </p>

      <h2>3. License and Usage Rights</h2>
      <p>
        We grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for personal or non-commercial purposes, subject to these Terms. The Service is provided "as is" and "as available," without warranties of any kind, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or accuracy of sentiment analysis results. We do not guarantee that the Service will be uninterrupted, error-free, or entirely reliable.
      </p>

      <h2>4. User Responsibilities</h2>
      <p>You agree to:</p>
      <ul>
        <li>Provide accurate and lawful input (e.g., keywords or phrases) when using the Service.</li>
        <li>Use the Service only for lawful purposes and in a manner that does not infringe on the rights of others, including intellectual property rights.</li>
        <li>Accept responsibility for any content you submit to the Service and the consequences of its use.</li>
      </ul>
      <p>We are not responsible for the accuracy, legality, or appropriateness of the Reddit posts analyzed by the Service, as this content is generated by third-party users on Reddit.</p>

      <h2>5. Prohibited Conduct</h2>
      <p>You may not:</p>
      <ul>
        <li>Use the Service for any illegal, harmful, fraudulent, or abusive activities, including harassment, defamation, or the promotion of hate speech or violence.</li>
        <li>Attempt to disrupt, interfere with, or compromise the security or functionality of the Service, including through the use of viruses, malware, or denial-of-service attacks.</li>
        <li>Reverse-engineer, decompile, disassemble, or otherwise attempt to derive the source code or underlying algorithms of the Service.</li>
        <li>Scrape, harvest, or extract data from the Service beyond its intended use, or use automated bots or scripts without our prior written consent.</li>
        <li>Misrepresent your identity or affiliation when using the Service.</li>
      </ul>
      <p>Violation of these prohibitions may result in immediate suspension or termination of your access to the Service, and we may report illegal activities to the appropriate authorities.</p>

      <h2>6. Intellectual Property</h2>
      <p>
        All content, features, and functionality of the Service, including but not limited to text, graphics, logos, and software, are the exclusive property of RedditSentiments or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of the Service or its content without our express written permission.
      </p>
      <p>
        The sentiment analysis results generated by the Service are provided for your personal use only. You may not republish, sell, or otherwise commercialize these results without our prior consent.
      </p>

      <h2>7. Third-Party Content and Links</h2>
      <p>
        The Service analyzes publicly available Reddit posts, which are created by third parties not affiliated with RedditSentiments. We do not endorse, control, or assume responsibility for the accuracy, legality, or quality of such third-party content. The Service may also include links to external websites; we are not responsible for the content or practices of these sites.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, RedditSentiments, its affiliates, officers, employees, and agents will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of or inability to use the Service. This includes, but is not limited to, damages for loss of profits, data, or goodwill, even if we have been advised of the possibility of such damages. In no event will our total liability exceed $100 USD.
      </p>

      <h2>9. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless RedditSentiments, its affiliates, and their respective officers, directors, employees, and agents from any claims, damages, losses, or expenses (including reasonable legal fees) arising out of your use of the Service, your violation of these Terms, or your infringement of any third-party rights.
      </p>

      <h2>10. Modifications to the Terms</h2>
      <p>
        We may update these Terms from time to time to reflect changes in our Service, legal requirements, or operational practices. Any updates will be posted on this page with an updated "Effective Date." Your continued use of the Service after such changes constitutes your acceptance of the revised Terms. We encourage you to review this page periodically.
      </p>

      <h2>11. Termination</h2>
      <p>
        We may suspend or terminate your access to the Service at any time, with or without notice, for any reason, including if we believe you have violated these Terms. Upon termination, your license to use the Service ends immediately, and you must cease all use. Sections 5 (Prohibited Conduct), 8 (Limitation of Liability), and 9 (Indemnification) will survive termination.
      </p>

      <h2>12. Force Majeure</h2>
      <p>
        RedditSentiments will not be liable for any failure or delay in providing the Service due to events beyond our reasonable control, including but not limited to natural disasters, power outages, internet failures, acts of war, or government actions.
      </p>

      <h2>13. Contact Us</h2>
      <p>
        If you have questions about these Terms or the Service, please contact us at <a href="mailto:support@redditsentiments.com">support@redditsentiments.com</a>.
      </p>

    </div>
  );
};

// Inline styles for the container
const containerStyle = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '2rem',
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  fontFamily: "'Arial', sans-serif",
};

export default TermsOfUse;