import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavigationHeader = () => {
  const location = useLocation();

  const linkStyle = {
    color: '#0B4B97',
    textDecoration: 'none',
    padding: '8px 16px',
    fontSize: '1.1rem',
    transition: 'all 0.3s ease',
    position: 'relative'
  };

  const activeLinkStyle = {
    ...linkStyle,
    fontWeight: 'bold',
    color: '#2563eb'
  };

  const hoverEffect = (e) => {
    e.target.style.color = '#2563eb';
    e.target.style.transform = 'translateY(-2px)';
  };

  const hoverEffectEnd = (e) => {
    e.target.style.color = '#0B4B97';
    e.target.style.transform = 'translateY(0)';
  };

  const getLinkStyle = (path) => {
    return location.pathname === path ? activeLinkStyle : linkStyle;
  };

  return (
    <div style={{
      width: '100%',
      backgroundColor: '#faf6f5',
      padding: '20px 40px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1200px',
        margin: '0 auto',
      }}>
        <h1 style={{
          color: '#0B4B97',
          fontSize: '1.8rem',
          margin: 0,
          fontFamily: 'Ubuntu',
          cursor: 'pointer'
        }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            SPJ NeuroScienceLab
          </Link>
        </h1>

        <nav style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center'
        }}>
          <Link
            to="/"
            style={getLinkStyle('/')}
            onMouseEnter={hoverEffect}
            onMouseLeave={hoverEffectEnd}
          >
            Home
          </Link>
          <Link
            to="/how-to-use"
            style={getLinkStyle('/how-to-use')}
            onMouseEnter={hoverEffect}
            onMouseLeave={hoverEffectEnd}
          >
            How to Use
          </Link>
          <Link
            to="/contact-us"
            style={getLinkStyle('/contact-us')}
            onMouseEnter={hoverEffect}
            onMouseLeave={hoverEffectEnd}
          >
            Contact Us
          </Link>
          <Link
            to="/privacy-policy"
            style={getLinkStyle('/privacy-policy')}
            onMouseEnter={hoverEffect}
            onMouseLeave={hoverEffectEnd}
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-of-use"
            style={getLinkStyle('/terms-of-use')}
            onMouseEnter={hoverEffect}
            onMouseLeave={hoverEffectEnd}
          >
            Terms of Use
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default NavigationHeader;