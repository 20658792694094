import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container" style={containerStyle}>
      {/* Scoped CSS */}
      <style jsx>{`
        .privacy-container h1 {
          font-size: 2.5rem;
          color: #1a3c5e;
          text-align: center;
          margin-bottom: 2rem;
        }
        .privacy-container h2 {
          font-size: 1.5rem;
          color: #2b5d87;
          margin-top: 2rem;
          margin-bottom: 1rem;
          border-bottom: 2px solid #e0e7ef;
          padding-bottom: 0.5rem;
        }
        .privacy-container p, .privacy-container li {
          font-size: 1rem;
          line-height: 1.6;
          color: #333;
          margin-bottom: 1rem;
        }
        .privacy-container ul {
          list-style-type: disc;
          margin-left: 2rem;
        }
        .privacy-container a {
          color: #007bff;
          text-decoration: none;
        }
        .privacy-container a:hover {
          text-decoration: underline;
        }
        @media (max-width: 768px) {
          .privacy-container {
            padding: 1rem;
          }
          .privacy-container h1 {
            font-size: 2rem;
          }
          .privacy-container h2 {
            font-size: 1.25rem;
          }
        }
      `}</style>

      <h1>Privacy Policy</h1>
      <p style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
        Effective Date: November 29, 2024
      </p>

      <p>
        At RedditSentiments, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and handle information when you use our website and services (collectively, the "Service").
      </p>

      <h2>1. Information We Collect</h2>
      <p><strong>Public Reddit Data:</strong> The Service analyzes publicly available posts from Reddit to provide sentiment analysis. We do not store this data in a database; we only process it temporarily to generate results and improve our natural language processing (NLP) model.</p>
      <p><strong>User Input:</strong> When you use the Service, you may provide keywords or phrases to guide the sentiment analysis. We do not retain this input beyond the duration of your session.</p>
      <p><strong>Usage Data:</strong> We may collect minimal technical information (e.g., IP address, browser type, or device information) to ensure the Service functions properly and to troubleshoot issues. This data is not linked to your identity and is not stored long-term.</p>

      <h2>2. How We Use Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide sentiment analysis based on Reddit posts.</li>
        <li>Fine-tune our NLP model to improve the accuracy and performance of the Service.</li>
        <li>Monitor and maintain the functionality and security of the Service.</li>
      </ul>
      <p>We do not use your information for advertising, profiling, or any purpose unrelated to the operation of RedditSentiments.</p>

      <h2>3. Data Storage and Retention</h2>
      <p>
        RedditSentiments does not maintain a database of Reddit posts or user inputs. Public Reddit data is processed in real-time and discarded after analysis. Any data used to hyper-tune our NLP model is anonymized, aggregated, and retained only as long as necessary to improve the model, after which it is deleted. Usage data, if collected, is kept only temporarily and in a non-identifiable form.
      </p>

      <h2>4. Sharing of Information</h2>
      <p>We do not sell, trade, or share your personal information with third parties, except:</p>
      <ul>
        <li>As required by law (e.g., to comply with a subpoena or legal process).</li>
        <li>To protect the rights, safety, or security of RedditSentiments, its users, or the public.</li>
      </ul>

      <h2>5. Cookies and Tracking</h2>
      <p>
        The Service does not use cookies or similar tracking technologies to monitor your activity. Any interaction with the Service is stateless beyond your current session.
      </p>

      <h2>6. Third-Party Services</h2>
      <p>
        The Service relies on public Reddit data, which is sourced directly from Reddit’s platform. We are not responsible for Reddit’s privacy practices. Please review <a href="https://www.reddit.com/policies/privacy-policy" target="_blank" rel="noopener noreferrer">Reddit’s privacy policy</a> for more information about how they handle data.
      </p>

      <h2>7. Security</h2>
      <p>
        We take reasonable measures to protect the limited information we process from unauthorized access, loss, or misuse. However, no system is entirely secure, and we cannot guarantee absolute security.
      </p>

      <h2>8. Your Rights</h2>
      <p>
        Since we do not store personal data beyond your session, there is no personal account or data to access, modify, or delete. If you have concerns about your privacy, please contact us.
      </p>

      <h2>9. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy as needed. Changes will be posted here with an updated "Effective Date." Your continued use of the Service after such updates indicates your acceptance of the revised policy.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        Questions about this Privacy Policy? Reach out to us at <a href="mailto:support@redditsentiments.com">support@redditsentiments.com</a>.
      </p>
    </div>
  );
};

// Inline styles for the container
const containerStyle = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '2rem',
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  fontFamily: "'Arial', sans-serif",
};

export default PrivacyPolicy;