import React from 'react';
import './Story.css'; // You'll need to create this CSS file

const Story = () => {
  return (
    <div className="story-container">
      <div className="story-content">
        <h2 className="story-title">Our Story</h2>

        <p className="story-paragraph">
          In a world driven by conversations, understanding public sentiment has never been more important.
          Yet, most social media listening tools lock meaningful insights behind paywalls, making it difficult
          for students, researchers, and small creators to access the data they need.
        </p>

        <p className="story-paragraph">
          We believe knowledge should be free and accessible to everyone. That's why we built
          <span className="highlighted-text"> RedditSentiments</span> — a powerful sentiment analysis
          tool that lets you explore public opinion without limitations.
        </p>

        <p className="story-paragraph">
          Whether you're a student researching social trends, a marketer analyzing brand perception,
          or just curious about what people think, our tool gives you the insights you need, completely
          free of charge.
        </p>

        <div className="feature-box">
          <p className="feature-text">
            By allowing up to 1000 Reddit posts per search, including both recent and popular discussions,
            we ensure you get a rich dataset to explore sentiment, visualize trends, and download results
            for deeper analysis. No subscriptions, no hidden fees — just open access to valuable social data.
          </p>
        </div>

        <p className="story-paragraph">
          We created RedditSentiments because we know what it's like to hit a paywall when you're just
          trying to learn. And we believe the power of sentiment analysis should be in everyone's hands,
          not just those who can afford it.
        </p>

        <p className="story-paragraph conclusion">
          Join us in democratizing social media insights — one keyword at a time.
        </p>
      </div>
    </div>
  );
};

export default Story;