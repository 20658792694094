import React from 'react';

const Example = () => {
  // Define styles as objects
  const styles = {
    container: {
      maxWidth: '1000px',
      margin: '0 auto',
      padding: '30px',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      boxShadow: '0 5px 20px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif'
    },
    header: {
      textAlign: 'center',
      marginBottom: '40px'
    },
    title: {
      fontSize: '42px',
      fontWeight: '700',
      color: '#0B4B97',
      marginBottom: '12px'
    },
    subtitle: {
      fontSize: '16px',
      color: '#666',
      maxWidth: '800px',
      margin: '0 auto',
      lineHeight: '1.6'
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '90%',
      marginBottom: '40px',
      gap: '30px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    visualContainer: {
      flex: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageTileWrapper: {
      position: 'relative',
      width: '650px', // Smaller image size
      height: '400px', // Smaller image size
    },
    imageTile: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
      border: '5px solid white',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease'
    },
    tileOverlay: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
      color: 'white',
      padding: '10px',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      textAlign: 'center'
    },
    contentContainer: {
      flex: '1.5', // Give more space to content
      padding: '10px'
    },
    sectionTitle: {
      fontSize: '22px',
      fontWeight: '600',
      color: '#333',
      marginBottom: '12px'
    },
    sectionDescription: {
      fontSize: '15px',
      color: '#666',
      lineHeight: '1.6',
      marginBottom: '16px'
    },
    legend: {
      display: 'flex',
      gap: '16px',
      flexWrap: 'wrap'
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '13px',
      color: '#777'
    },
    legendColorDot: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      marginRight: '6px'
    }
  };

  // Hover state for image tiles
  const [hoverTile1, setHoverTile1] = React.useState(false);
  const [hoverTile2, setHoverTile2] = React.useState(false);

  return (
    <div>
      <div style={styles.header}>
        <h2 style={styles.title}>Analysis Results</h2>
        <p style={styles.subtitle}>
          Understanding online conversations is more than just reading comments — it's about uncovering patterns, trends, and emotions. Here’s how RedditSentiments transforms raw posts into powerful insights:
        </p>
      </div>

      {/* First section*/}
      <div style={styles.section}>
        <div style={styles.visualContainer}>
          <div style={styles.imageTileWrapper}>
            <img
              src="/images/sentiment-line-chart.png"
              alt="Line Chart"
              style={{
                ...styles.imageTile,
                transform: hoverTile1 ? 'scale(1.03)' : 'scale(1)',
                boxShadow: hoverTile1 ? '0 8px 20px rgba(0, 0, 0, 0.2)' : '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
              onMouseEnter={() => setHoverTile1(true)}
              onMouseLeave={() => setHoverTile1(false)}
            />
          </div>
        </div>
        <div style={styles.contentContainer}>
          <h3 style={styles.sectionTitle}>📈 Line Chart: Sentiment Over Time</h3>
          <p style={styles.sectionDescription}>Track sentiment shifts as conversations evolve. The line chart captures how positive, neutral, and negative sentiments change over time, so you can easily identify trends:</p>
          <ul>
            <li style={styles.sectionDescription}><b>Monitor brand perception:</b> See how public opinion fluctuates after product launches or breaking news.</li>
            <li style={styles.sectionDescription}><b>Spot emotional spikes:</b> Find the exact moments when sentiment changes and investigate why.</li>
            <li style={styles.sectionDescription}><b>Compare sentiment patterns:</b> Analyze multiple searches to see how different topics trend over time.</li>
          </ul>
          <p style={styles.sectionDescription}>By visualizing sentiment chronologically, you gain a timeline of public emotion — helping you make timely decisions or refine your strategy.</p>
        </div>
      </div>

      {/* Second section: Description on left, word cloud on right */}
      <div style={styles.section}>
        <div style={styles.contentContainer}>
          <h3 style={styles.sectionTitle}>☁️ Word Cloud: Top Keywords</h3>
          <p style={styles.sectionDescription}>Uncover the most talked-about topics at a glance.
            The word cloud highlights frequently mentioned words, with larger words appearing more often in posts. This makes it easy to identify key discussion points:</p>
          <ul>
            <li style={styles.sectionDescription}><b>Discover emerging trends:</b> Spot new buzzwords, viral phrases, or hashtags that are gaining traction.</li>
            <li style={styles.sectionDescription}><b>Identify pain points and praises:</b> Words like “issue,” “love,” or “expensive” can quickly indicate what people care about most.</li>
            <li style={styles.sectionDescription}><b>Find community slang and niche terms:</b> Reddit is full of unique language — the word cloud helps you catch those platform-specific expressions.</li>
          </ul>
          <p style={styles.sectionDescription}>By visualizing sentiment chronologically, you gain a timeline of public emotion — helping you make timely decisions or refine your strategy.</p>
        </div>
        <div style={styles.visualContainer}>
          <div style={styles.imageTileWrapper}>
            <img
              src="/images/wordcloud.png"
              alt="Word Cloud Visualization"
              style={{
                ...styles.imageTile,
                transform: hoverTile2 ? 'scale(1.03)' : 'scale(1)',
                boxShadow: hoverTile2 ? '0 8px 20px rgba(0, 0, 0, 0.2)' : '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
              onMouseEnter={() => setHoverTile2(true)}
              onMouseLeave={() => setHoverTile2(false)}
            />
          </div>
        </div>
      </div>

      {/*Third part - Pie Chart*/}
      <div style={styles.section}>
        <div style={styles.visualContainer}>
          <div style={styles.imageTileWrapper}>
            <img
              src="/images/pie.png"
              alt="Sentiment Distribution Pie Chart"
              style={{
                ...styles.imageTile,
                transform: hoverTile1 ? 'scale(1.03)' : 'scale(1)',
                boxShadow: hoverTile1 ? '0 8px 20px rgba(0, 0, 0, 0.2)' : '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
              onMouseEnter={() => setHoverTile1(true)}
              onMouseLeave={() => setHoverTile1(false)}
            />
          </div>
        </div>
        <div style={styles.contentContainer}>
          <h3 style={styles.sectionTitle}>🥧 Pie Chart: Sentiment Distribution</h3>
          <p style={styles.sectionDescription}>Quickly gauge the overall sentiment landscape.
The sentiment pie chart breaks posts into positive, neutral, and negative categories, giving you a high-level view of public sentiment:</p>
          <ul style={styles.sectionDescription}>
            <li ><b>Assess overall public opinion:</b> Instantly see whether the majority of discussions lean positive, negative, or neutral.</li>
            <li ><b>Understand audience polarity:</b> If negativity dominates, this could signal dissatisfaction or controversy that needs attention.</li>
            <li ><b>Benchmark sentiment across topics:</b> Run multiple searches and compare pie charts to see which topics generate the most positivity or controversy.</li>
          </ul>
          <p style={styles.sectionDescription}>This immediate visual snapshot helps brands, researchers, and social media managers quickly understand public sentiment without wading through individual posts.</p>
        </div>
      </div>
      {/*Fourth part - AI Insights*/}
      <div style={styles.section}>
        <div style={styles.contentContainer}>
          <h3 style={styles.sectionTitle}>🤖 AI Insights: Beyond the Basics</h3>
          <p style={styles.sectionDescription}>Let AI do the heavy lifting.
Our AI-powered insights feature is what makes RedditSentiments truly stand out. Beyond just classifying sentiment, our model digs deeper:</p>
          <ul style={styles.sectionDescription}>
            <li ><b>Detect complex emotions:</b> Capture nuances like sarcasm, joy, frustration, or excitement — emotions that simple sentiment analysis might miss.</li>
            <li ><b>Identify recurring discussion patterns:</b> The AI can surface repetitive themes, letting you see which topics or subtopics are dominating the conversation.</li>
            <li ><b>Explain sentiment spikes:</b> Get context for sudden changes — whether it’s a viral post, a controversial comment, or breaking news that altered public opinion.</li>
            <li ><b>Suggest next steps:</b> For brands or creators, the AI can offer data-driven suggestions, like addressing common complaints or doubling down on positive feedback.</li>
          </ul>
          <p style={styles.sectionDescription}>With these advanced insights, you don’t just get numbers — you get a richer, more meaningful understanding of online sentiment.</p>
        </div>
        <div style={styles.visualContainer}>
          <div style={styles.imageTileWrapper}>
            <img
              src="/images/insight.png"
              alt="AI Insights Visualization"
              style={{
                ...styles.imageTile,
                transform: hoverTile2 ? 'scale(1.03)' : 'scale(1)',
                boxShadow: hoverTile2 ? '0 8px 20px rgba(0, 0, 0, 0.2)' : '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
              onMouseEnter={() => setHoverTile2(true)}
              onMouseLeave={() => setHoverTile2(false)}
            />
          </div>
        </div>
      </div>
      {/*Fifth - CSV Download Free*/}
      <div style={styles.section}>
        <div style={styles.visualContainer}>
          <div style={styles.imageTileWrapper}>
            <img
              src="/images/csv.png"
              alt="csv Download"
              style={{
                ...styles.imageTile,
                transform: hoverTile1 ? 'scale(1.03)' : 'scale(1)',
                boxShadow: hoverTile1 ? '0 8px 20px rgba(0, 0, 0, 0.2)' : '0 4px 12px rgba(0, 0, 0, 0.15)'
              }}
              onMouseEnter={() => setHoverTile1(true)}
              onMouseLeave={() => setHoverTile1(false)}
            />
          </div>
        </div>
        <div style={styles.contentContainer}>
          <h3 style={styles.sectionTitle}>📂 Free CSV Download: Take Your Data Further</h3>
          <p style={styles.sectionDescription}>Your data, your way — without the paywall.
Unlike many sentiment analysis tools, RedditSentiments lets you download your data for free. Get a detailed CSV file containing:</p>
          <ul style={styles.sectionDescription}>
            <li ><b>Full post content and metadata:</b> See exactly what people are saying, along with timestamps, subreddit names, and upvote counts.</li>
            <li ><b>Sentiment scores: </b> Every post is assigned a sentiment score, so you can sort, filter, and analyze sentiment at a granular level.</li>
            <li ><b>Keyword frequency counts:</b> See how often specific words appear, helping you quantify the importance of certain terms.</li>
            <li ><b>Customizable research opportunities:</b> Whether you’re doing academic research, competitive analysis, or content planning, having the raw data means you can run your own analyses.</li>
          </ul>
          <p style={styles.sectionDescription}>This feature makes RedditSentiments perfect for students, researchers, and small businesses — empowering them with data without locking insights behind a paywall.</p>
        </div>
      </div>
    </div>
  );
};

export default Example;